import { Navigate } from 'react-router-dom';
import jwtDecode from 'jwt-decode';

// eslint-disable-next-line react/prop-types
const AuthGuard = ({ children }) => {
    const x_access_token = localStorage.getItem('x_access_token');
    const token = x_access_token;
    if (!token) {
        return <Navigate to="/login" replace={true} />;
    }

    const decodedToken = jwtDecode(token);
    if (decodedToken.exp * 1000 > new Date().getTime()) {
        return children;
    }
    return <Navigate to="/login" replace={true} />;
};

export default AuthGuard;
