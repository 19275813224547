// action - customization reducer
export const SET_MENU = '@customization/SET_MENU';
export const MENU_TOGGLE = '@customization/MENU_TOGGLE';
export const MENU_OPEN = '@customization/MENU_OPEN';
export const SET_FONT_FAMILY = '@customization/SET_FONT_FAMILY';
export const SET_BORDER_RADIUS = '@customization/SET_BORDER_RADIUS';
export const UPDATE_ADMIN = 'UPDATE_ADMIN';
export const UPDATE_ADMINS = 'UPDATE_ADMINS';
export const UPDATE_ALERT = 'UPDATE_ALERT';
export const START_LOADING = 'START_LOADING';
export const END_LOADING = 'END_LOADING';
export const OPEN_ADD_ADMIN = 'OPEN_ADD_ADMIN';
export const CLOSE_ADD_ADMIN = 'CLOSE_ADD_ADMIN';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const UPDATE_COMPANIES = 'UPDATE_COMPANIES';
export const UPDATE_ADD_COMPANY = 'UPDATE_ADD_COMPANY';
export const UPDATE_MAIN_STRUCTURES = 'UPDATE_MAIN_STRUCTURES';
export const UPDATE_ADD_MAIN_STRUCTURE = 'UPDATE_ADD_MAIN_STRUCTURE';
export const OPEN_DELETE_MAIN_STRUCTURE = 'OPEN_DELETE_MAIN_STRUCTURE';
export const UPDATE_TALENTS = 'UPDATE_TALENTS';
export const UPDATE_ADD_TALENT = 'UPDATE_ADD_TALENT';
export const COMPANY_LEFT_SIDE = 'COMPANY_LEFT_SIDE';
export const COMPANY_ABOUT_DATA = 'COMPANY_ABOUT_DATA';
export const COMPANY_SOCIAL_DATA = 'COMPANY_SOCIAL_DATA';
export const COMPANY_MAINSTRUCT_DATA = 'COMPANY_MAINSTRUCT_DATA';
export const COMPANY_BRANCHES_DATA = 'COMPANY_BRANCHES_DATA';
export const COMPANY_STAFF_DATA = 'COMPANY_STAFF_DATA';
export const COMPANY_OUTSOURCE_DATA = 'COMPANY_OUTSOURCE_DATA';
export const COMPANY_CLIENTS_DATA = 'COMPANY_CLIENTS_DATA';
export const OPEN_UPDATE_PROFILE_IMAGE = 'OPEN_UPDATE_PROFILE_IMAGE';
export const OPEN_EDIT_MODAL = 'OPEN_EDIT_MODAL';
export const OPEN_APPEND_ARRAY_MODAL = 'OPEN_APPEND_ARRAY_MODAL';
export const OPEN_EDIT_LOGOS_ARRAY = 'OPEN_EDIT_LOGOS_ARRAY';
export const OPEN_ADD_LOGOS_ARRAY = 'OPEN_ADD_LOGOS_ARRAY';
export const GET_TALENT_PROFILE_DATA = 'GET_TALENT_PROFILE_DATA';
export const CHOOSE_LOGIN_TYPE_MODAL = 'CHOOSE_LOGIN_TYPE_MODAL';
export const UPDATE_CURRENT_TALENT = 'UPDATE_CURRENT_TALENT';
export const OPEN_EDIT_MODAL_TALENT = 'OPEN_EDIT_MODAL_TALENT';
export const OPEN_APPEND_ARRAY_MODAL_TALENT = 'OPEN_APPEND_ARRAY_MODAL_TALENT';
export const OPEN_UPDATE_PROFILE_IMAGE_TALENT = 'OPEN_UPDATE_PROFILE_IMAGE_TALENT';
export const ADD_FIELD_OPTION = 'ADD_FIELD_OPTION';
export const OPEN_DELETE_TALENT = 'OPEN_DELETE_TALENT';
