import { UPDATE_ALERT } from '../../actions';

const fetchData = async ({ url, method = 'POST', token = '', body = null }, dispatch) => {
    const headers = token ? { 'Content-Type': 'application/json', authorization: token } : { 'Content-Type': 'application/json' };
    body = body ? { body: JSON.stringify(body) } : {};
    try {
        const response = await fetch(url, { method, headers, ...body });
        const data = await response.json();
        if (!data.success) {
            if (response.status === 401 || response.status === 501) dispatch({ type: 'UPDATE_ADMIN', payload: null });
            dispatch({
                type: UPDATE_ALERT,
                payload: {
                    open: true,
                    severity: 'error',
                    message: data.message
                }
            });
        }
        return data.result;
    } catch (error) {
        console.log(error);
        return null;
    }
};

export default fetchData;
