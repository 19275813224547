import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from './AuthGuard';
import TalentControl from 'views/talents/talentControl';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));

// administration routing
const ManageAdmins = Loadable(lazy(() => import('views/administration/ManageAdmins')));

// companies routing
const Companies = Loadable(lazy(() => import('views/companies/Companies')));
const EditCompanyProfile = Loadable(lazy(() => import('views/companies/editProfile/EditCompanyProfile')));

// main structures routing
const MainStructure = Loadable(lazy(() => import('views/companies/mainStructure/MainStructure')));

// talents routing
const Talents = Loadable(lazy(() => import('views/talents/Talents')));
const EditTalentProfile = Loadable(lazy(() => import('views/talents/editProfile/EditTalentProfile')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/',
            element: <DashboardDefault />
        },
        {
            path: 'dashboard',
            children: [
                {
                    path: '',
                    element: <DashboardDefault />
                }
            ]
        },
        {
            path: 'administration',
            children: [
                {
                    path: 'manage-admins',
                    element: <ManageAdmins />
                }
            ]
        },
        {
            path: 'companies',
            children: [
                {
                    path: '',
                    element: <Companies />
                },
                { path: 'main-structures', element: <MainStructure /> },
                {
                    path: ':id',
                    element: <EditCompanyProfile />
                }
            ]
        },
        {
            path: 'talents',
            children: [
                {
                    path: '',
                    element: <Talents />
                },
                {
                    path: ':id',
                    element: <EditTalentProfile />
                },
                {
                    path: 'fields',
                    element: <TalentControl />
                }
            ]
        }
    ]
};

export default MainRoutes;
