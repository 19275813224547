import administration from './administration';
import manageCompanies from './manageCompanies';
import manageTalents from './manageTalents';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [administration, manageCompanies, manageTalents]
};

export default menuItems;
