// assets
import { IconUsers } from '@tabler/icons';

// constant
const icons = {
    IconUsers
};

// ==============================|| Mobile App MENU ITEMS ||============================== //

const manageTalents = {
    id: 'manageTalents',
    title: 'Manage Talents',
    caption: 'Talent Config',
    type: 'group',
    children: [
        {
            id: 'talent',
            title: 'Talents',
            type: 'collapse',
            icon: icons.IconUsers,
            children: [
                {
                    id: 'talents',
                    title: 'All Talents',
                    type: 'item',
                    url: '/talents'
                },
                {
                    id: 'talentsControl',
                    title: 'Control Fields',
                    type: 'item',
                    url: '/talents/fields'
                }
            ]
        }
    ]
};

export default manageTalents;
