// project imports
import fetchData from '../utils/fetchData';
import { ENDPOINT_BASE_URL } from '../../../constants';
import {
    UPDATE_TALENTS,
    UPDATE_ADD_TALENT,
    START_LOADING,
    END_LOADING,
    UPDATE_ALERT,
    ADD_FIELD_OPTION,
    OPEN_DELETE_TALENT
} from '../../actions';

const url = ENDPOINT_BASE_URL;

export const getTalents = async (dispatch) => {
    dispatch({ type: START_LOADING });

    const result = await fetchData({ url: url + '/api/talent/dash/all', method: 'GET' }, dispatch);
    if (result) {
        dispatch({ type: UPDATE_TALENTS, payload: result.talents });
    }

    dispatch({ type: END_LOADING });
};

export const addTalent = async (talent, body, dispatch) => {
    dispatch({ type: START_LOADING });

    try {
        const result = await fetchData(
            {
                url: url + `/api/talent/add`,
                method: 'POST',
                body
            },
            dispatch
        );
        if (result) {
            dispatch({
                type: UPDATE_ALERT,
                payload: {
                    open: true,
                    severity: 'success',
                    message: 'Talent added successfully'
                }
            });
            dispatch({
                type: UPDATE_ADD_TALENT,
                payload: { ...talent, open: false }
            });
            getTalents(dispatch);
        }
    } catch (error) {
        dispatch({
            type: UPDATE_ALERT,
            payload: {
                open: true,
                severity: 'error',
                message: error.message
            }
        });
    }

    dispatch({ type: END_LOADING });
};

export const updateTalent = async (active, id, dispatch, isPassword) => {
    if (isPassword) {
        return fetchData({ url: url + `/api/talent/update-pass/${id}`, method: 'PATCH', body: { password: active } }, dispatch);
    } else return fetchData({ url: url + `/api/talent/status/${id}`, method: 'PATCH', body: { active } }, dispatch);
};

export const addOption = async (data, dispatch) => {
    dispatch({ type: START_LOADING });

    const result = await fetchData({ url: url + '/api/dashboard/talent/fields', method: 'POST', body: data }, dispatch);
    if (result) {
        dispatch({ type: ADD_FIELD_OPTION, payload: result });
    }

    dispatch({ type: END_LOADING });
};

export const getOption = async (data, dispatch) => {
    dispatch({ type: START_LOADING });

    const result = await fetchData({ url: url + `/api/dashboard/talent/fields/${data}`, method: 'GET' }, dispatch);
    if (result) {
        dispatch({ type: ADD_FIELD_OPTION, payload: result });
    }

    dispatch({ type: END_LOADING });
};

export const removeOption = async (data, dispatch) => {
    dispatch({ type: START_LOADING });

    const result = await fetchData({ url: url + `/api/dashboard/talent/fields`, method: 'DELETE', body: data }, dispatch);
    if (result) {
        dispatch({ type: ADD_FIELD_OPTION, payload: result });
    }

    dispatch({ type: END_LOADING });
};

export const updateOption = async (data, dispatch) => {
    dispatch({ type: START_LOADING });

    const result = await fetchData({ url: url + '/api/dashboard/talent/fields', method: 'PUT', body: data }, dispatch);
    if (result) {
        dispatch({ type: ADD_FIELD_OPTION, payload: result });
    }

    dispatch({ type: END_LOADING });
};

export const deleteTalents = async (deleteTalent, id, dispatch) => {
    dispatch({ type: START_LOADING });

    try {
        const result = await fetchData(
            {
                url: url + `/api/talent/delete/${id}`,
                method: 'DELETE',
                body: {}
            },
            dispatch
        );
        if (result) {
            dispatch({
                type: UPDATE_ALERT,
                payload: {
                    open: true,
                    severity: 'success',
                    message: 'Talent deleted'
                }
            });
            dispatch({
                type: OPEN_DELETE_TALENT,
                payload: { ...deleteTalent, open: false, id: '' }
            });

            getTalents(dispatch);
        }
    } catch (error) {
        dispatch({
            type: UPDATE_ALERT,
            payload: {
                open: true,
                severity: 'error',
                message: error.message
            }
        });
    }

    dispatch({ type: END_LOADING });
};
