import { createStore, applyMiddleware } from 'redux';
import reducer from './reducer';
import { routerMiddleware } from 'react-router-redux';
// Apply the middleware to the store
import { createHashHistory } from 'history';
const browserHistory = createHashHistory();
const middleware = routerMiddleware(browserHistory);

// ==============================|| REDUX - MAIN STORE ||============================== //

const store = createStore(reducer, applyMiddleware(middleware));
const persister = 'Free';

export { store, persister };
