// assets
import { IconKey } from '@tabler/icons';

// constant
const icons = {
    IconKey
};

// ==============================|| Administration MENU ITEMS ||============================== //

const administration = {
    id: 'administration',
    title: 'administration',
    type: 'group',
    children: [
        {
            id: 'manage-admins',
            title: 'Manage Admins',
            type: 'item',
            url: '/administration/manage-admins',
            icon: icons.IconKey,
            breadcrumbs: false
        }
    ]
};

export default administration;
