import { Box } from '@mui/system';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import styles from './talentControl.module.css';
import RadioResultItems from './talentControlItems';
import { useState } from 'react';

const TalentControl = () => {
    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary
    }));
    const fields_arr = [
        'religion',
        'martialStatus',
        'professionalStatus',
        'workStatus',
        'availability',
        'education',
        'militaryStatus',
        'gender',
        'careerSector',
        'orientation',
        'priority',
        'smoking'
    ];
    const [category, setCategory] = useState('religion');
    const handelChangeRadio = (event) => {
        setCategory(event.target.value);
    };

    const fields_Radio_Component = fields_arr.map((field, index) => {
        return <FormControlLabel color="success" key={index} value={field} control={<Radio />} label={field} />;
    });
    return (
        <Box sx={{ height: '80vh', backgroundColor: 'white' }}>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={6}>
                    <Item>
                        <FormControl>
                            <FormLabel className={styles.FormLabel}>Fields Category</FormLabel>
                            <RadioGroup
                                onChange={handelChangeRadio}
                                aria-labelledby="demo-radio-buttons-group-label"
                                defaultValue="religion"
                                name="radio-buttons-group"
                                value={category}
                            >
                                {fields_Radio_Component}
                            </RadioGroup>
                        </FormControl>
                    </Item>
                </Grid>
                <Grid item xs={6}>
                    <Item>
                        <RadioResultItems category={category} />
                    </Item>
                </Grid>
            </Grid>
        </Box>
    );
};

export default TalentControl;
