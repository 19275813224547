import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import { IconButton } from '@mui/material';
import { useDispatch } from 'react-redux';
import { updateOption } from 'store/actions/talents';

// eslint-disable-next-line react/prop-types
export default function FormDialog({ old, category }) {
    const [open, setOpen] = React.useState(false);
    const [message, setMessage] = React.useState(old);
    const [empty, setEmpty] = React.useState(false);
    const dispatch = useDispatch();

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleChange = (event) => {
        setMessage(event.target.value);
    };
    const handelSave = () => {
        if (message.trim()) {
            setEmpty(false);
            updateOption({ field: category, oldValue: old, newValue: message }, dispatch);
            setOpen(false);
        } else {
            setEmpty(true);
        }
    };
    return (
        <div>
            <IconButton size="small" edge="end" aria-label="comments" onClick={handleClickOpen}>
                <BorderColorOutlinedIcon />
            </IconButton>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Edit Option</DialogTitle>
                <DialogContent>
                    <DialogContentText>Please enter save if you want to change field value. </DialogContentText>
                    <TextField
                        error={empty}
                        margin="dense"
                        id="name"
                        label="Option Value"
                        type="text"
                        fullWidth
                        variant="standard"
                        value={message}
                        onChange={handleChange}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handelSave}>Save</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
