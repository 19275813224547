// assets
import { IconReplace, IconBuildingCommunity } from '@tabler/icons';

// constant
const icons = {
    IconBuildingCommunity,
    IconReplace
};

// ==============================|| Mobile App MENU ITEMS ||============================== //

const manageCompanies = {
    id: 'manageCompanies',
    title: 'Manage Companies',
    caption: 'Company Config',
    type: 'group',
    children: [
        {
            id: 'company',
            title: 'Companies',
            type: 'collapse',
            icon: icons.IconBuildingCommunity,
            children: [
                {
                    id: 'companies',
                    title: 'All Companies',
                    type: 'item',
                    url: '/companies'
                },
                {
                    id: 'mainStructure',
                    title: 'Main Structure',
                    type: 'item',
                    url: '/companies/main-structures'
                }
            ]
        }
        // {
        //     id: 'redeem-gold',
        //     title: 'Redeem Requests',
        //     type: 'item',
        //     url: '/redeem-gold',
        //     icon: icons.IconReplace,
        //     breadcrumbs: false
        // }
    ]
};

export default manageCompanies;
