import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { ENDPOINT_BASE_URL } from 'constants';

//addOption
export const addOption = createAsyncThunk('fields/addOption', async (data, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    //console.log(data);

    try {
        const res = await axios.post(`${ENDPOINT_BASE_URL}/api/dashboard/talent/fields`, data);
        //console.log(res);
        return res.data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

const initialState = {
    allOptions: [],
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: ''
};

export const fieldsControl = createSlice({
    name: 'fields',
    initialState,
    reducers: {
        reset: (state) => {
            state.isLoading = false;
            state.isError = false;
            state.isSuccess = false;
            state.message = '';
        }
    },

    extraReducers: {
        //addOption
        // eslint-disable-next-line no-unused-vars
        [addOption.pending]: (state, action) => {
            //console.log(action);
            state.isLoading = true;
            state.isError = false;
        },
        [addOption.fulfilled]: (state, action) => {
            //console.log(action);
            state.isLoading = false;
            state.isSuccess = true;
            state.isError = false;
            state.allOptions = action.payload;
        },
        [addOption.rejected]: (state, action) => {
            //console.log(action);
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
            // state.allProjects = null;
        }
    }
});

export const { reset } = fieldsControl.actions;
export default fieldsControl.reducer;
